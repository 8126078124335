import React, { Component } from "react";
import moment from "moment";
import "moment/locale/fr";

import AnnonceItem from "../../Dashboard/Component/AnnonceItem";

// services import

import Propertie from "../../../../../services/propertie";

const currentUser = JSON.parse(localStorage.getItem("userInfo"));
const currentUserId = currentUser?.id;
const currentUsername = currentUser?.username;
class PendingAnnonce extends Component {
  constructor(props) {
    super(props);
    this.state = { propertieListe: [] };
  }

  getPendingProperties = () => {
    Propertie.getPendingPropertieListForAdmin().then((result) => {
      this.setState({ propertieListe: result.data });
    });
  };

  EditPropertieStatus = (propertieId, statusId) => {
    moment().locale("fr");
    const startdate = new Date();
    var new_date = moment(startdate, "DD-MM-YYYY").add(7, "d");
    const expireDate = moment(new_date)
      .locale("fr")
      .format("YYYY-MM-DD HH:mm:ss");

    let data = {
      propertieStatusId: 2,
      expire_date: expireDate,
    };

    Propertie.updatePropertie(propertieId, data).then(() =>
      this.componentDidMount()
    );
  };

  ablePropertie = (propertieId) => {
    moment().locale("fr");
    const startdate = new Date();
    var new_date = moment(startdate, "DD-MM-YYYY").add(7, "d");
    const expireDate = moment(new_date)
      .locale("fr")
      .format("YYYY-MM-DD HH:mm:ss");
    let data = {
      propertieStatusId: 3,
      expire_date: expireDate,
    };

    Propertie.updatePropertie(propertieId, data).then(() =>
      this.componentDidMount()
    );
  };

  componentDidMount() {
    this.getPendingProperties();
  }

  render() {
    const { propertieListe } = this.state;
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>En attente d'approbation</h2>
        </div>
        <div className="dsh-content">
          <div className="card user-active-properties-box">
            <h2 className="mb-4">
              <strong>
                Propriétés en attente d'approbation ({propertieListe.length}){" "}
              </strong>{" "}
            </h2>
            <div className="dashboard_table_list_header mb-3 row">
              <div className="col-md-4">Propriété</div>
              <div className="col-md-2">Catégorie</div>
              <div className="col-md-2">Statut</div>
              <div className="col-md-2">Prix </div>
              <div className="col-md-2">Actions</div>
            </div>
            {propertieListe.map((item, index) => (
              <AnnonceItem
                key={index}
                item={item}
                ablePropertie={this.ablePropertie}
                EditPropertieStatus={this.EditPropertieStatus}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PendingAnnonce;
