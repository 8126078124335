import React, { Component } from "react";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Loader_01 from "../../../../../loaders/loader_01";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DropzoneArea } from "material-ui-dropzone";

// services call
import Propertie from "../../../../../services/propertie";
import Categorie from "../../../../../services/categorie";
import Propertie_type from "../../../../../services/propertieType";
import Propertie_image from "../../../../../services/propertieImage";
import villes from "../../../../../services/villes";

import S3FileUpload from "react-s3";

//Optional Import
import { uploadFile } from "react-s3";
const config = {
  bucketName: "nymba-files",
  region: "us-west-2",
  accessKeyId: "AKIAQSFK33IDINH5ZBFI",
  secretAccessKey:
    "gIvTD3hgwow08/PKIzcNcd3PePdB4kGXwDmLkAu6+DEkgDxe6veFosBT7eUgEXAMPLE",
};

const currentUser = JSON.parse(localStorage.getItem("userInfo"));
const currentUsername = currentUser?.username;
class UpdateAnnonce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      propertieDetail: [],
      categorieListe: [],
      propertieTypeListe: [],
      zoneListe: [],
      villeCommuneListe: [],
      files: [],
      filelist: [],
      dataSending: false,
      categoryIdValidator: 0,
      propertieTypeIdValidator: 0,
      zoneIdValidator: 0,
      villeCommuneIdValidator: 0,
      inputValidator: false,
      successOp: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.handleChangCategory = this.handleChangCategory.bind(this);
    this.handleChangePropertieType = this.handleChangePropertieType.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.handleChangeVilleCommune = this.handleChangeVilleCommune.bind(this);
  }

  handleChange = (event) => {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = { ...this.state.item };
    item[name] = value;
    if (
      name == "propertieTypeId" ||
      name == "propertiePrivilegeId" ||
      name == "propertieProjetId" ||
      name == "villeCommuneId" ||
      name == "zoneId"
    ) {
      item[name] = Number(value);
    }
    this.setState({ item });
  };

  onEditorChange = (event, editor) => {
    const name = "body";
    const value = editor.getData();
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleChangCategory = (event) => {
    let item = { ...this.state.item };
    item["propertieProjetId"] = event.value;
    this.setState({ item });
  };

  handleChangePropertieType = (event) => {
    let item = { ...this.state.item };
    item["propertieTypeId"] = event.value;
    this.setState({ item });
  };

  handleChangeZone = (event) => {
    let item = { ...this.state.item };
    item["zoneId"] = event.value;
    this.setState({ item });
  };

  handleChangeVilleCommune = (event) => {
    let item = { ...this.state.item };
    item["villeCommuneId"] = event.value;
    this.setState({ item });
  };

  handleChangeFiles(files) {
    this.setState({
      files: files,
    });
  }

  savePropertieFile = () => {
    const files = { ...this.state.files };
    this.handleFileSubmission(files[0]);
  };

  handleFileSubmission = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    S3FileUpload.uploadFile(file, config)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };

  // services function section
  updatePropertie = (event) => {
    event.preventDefault();
    this.setState({ dataSending: true });
    if (this.state.files.length > 0) {
      this.savePropertieFile();
    }
    let item = { ...this.state.item };
    this.setState({ item });
    Propertie.updatePropertie(this.props.match.params.propertieId, item).then(
      (result) => {
        const formData = new FormData();
        // formData.append("files", this.state.files);
        // Read selected files
        for (let i = 0; i < this.state.files.length; i++) {
          const isformat = formData.append("files", this.state.files[i]);
          this.setState({
            filelist: [...this.state.filelist, isformat],
          });
        }
        formData.append("propertyId", this.props.match.params.propertieId);

        Propertie_image.createPropertie_image(formData).then((result) => {
          setTimeout(() => {
            this.setState({ dataSending: false, successOp: true });
            this.componentDidMount();
          }, 3000);
        });
      }
    );
  };

  getPropertieDetail(propertieId) {
    Propertie.getPropertieByHisId(propertieId).then((result) => {
      this.setState({ propertieDetail: result.data[0] });
    });
  }

  getCategorieList = () => {
    Categorie.getCategorieList().then((result) => {
      this.setState({
        categorieListe: result.data,
      });
    });
  };

  getPropertieTypeList = () => {
    Propertie_type.getPropertie_typeList().then((result) => {
      this.setState({
        propertieTypeListe: result.data,
      });
    });
  };
  getZoneList = () => {
    villes.getZone().then((result) => {
      this.setState({ zoneListe: result.data });
    });
  };
  getVilleCommuneList = () => {
    villes.getVillesCommune().then((result) => {
      this.setState({
        villeCommuneListe: result.data,
      });
    });
  };

  componentDidMount() {
    this.getPropertieDetail(this.props.match.params.propertieId);
    this.getCategorieList();
    this.getPropertieTypeList();
    this.getZoneList();
    this.getVilleCommuneList();
  }

  render() {
    const {
      item,
      propertieDetail,
      categorieListe,
      propertieTypeListe,
      zoneListe,
      villeCommuneListe,
      inputValidator,
      successOp,
    } = this.state;
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Mon profil</h2>
        </div>
        <div className="dsh-content">
          <div
            className="alert-with-icon alert alert-warning fade show"
            role="alert"
          >
            <div className="container">
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-bell-55"></i> Après soumission de
                  votre propriété, celle ci est soumise à une validation dans
                  les 48h avant d'etre publiée
                </div>
              </div>
            </div>
          </div>
          {successOp && (
            <div className="alert alert-success fade show" role="alert">
              <div className="container">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-check-2"></i> Votre propriété a été
                  mis à jour avec succès
                </div>
              </div>
            </div>
          )}
          <div className="card userinfo p-5 b-r-20">
            {" "}
            <div className="profile--item">
              <div className="profile-body">
                <form onSubmit={this.updatePropertie}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <p>
                        * Ces champs sont obligatoires : Titre,Type d'annonce,
                        Type de bien, Zone, Ville / commmune, Prix, Médias de
                        propriété
                      </p>
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Description du bien</h2>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>* Titre (obligatoire)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="title"
                          id="title"
                          onChange={this.handleChange}
                          defaultValue={propertieDetail.title}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>* Type d'annonce</label>
                      <select
                        name="propertieProjetId"
                        id="propertieProjetId"
                        className="form-control"
                        onChange={this.handleChange}
                        value={propertieDetail.propertieProjetId}
                      >
                        {categorieListe.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>* Type de bien</label>

                      <select
                        name="propertieTypeId"
                        id="propertieTypeId"
                        className="form-control"
                        onChange={this.handleChange}
                        value={propertieDetail.propertieTypeId}
                      >
                        {propertieTypeListe.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label>Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={propertieDetail.body ? propertieDetail.body : " "}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={this.onEditorChange}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Localisation</h2>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>* Zone</label>

                      <select
                        name="zoneId"
                        id="zoneId"
                        onChange={this.handleChange}
                        className="form-control"
                        value={propertieDetail.zoneId}
                      >
                        {zoneListe.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>* Ville / commmune</label>

                      <select
                        name="villeCommuneId"
                        id="villeCommuneId"
                        className="form-control"
                        onChange={this.handleChange}
                        value={propertieDetail.villeCommuneId}
                      >
                        {villeCommuneListe.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Tarif</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>* Prix en Fcfa (chiffre seulement)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder=""
                          name="prix"
                          id="prix"
                          onChange={this.handleChange}
                          defaultValue={propertieDetail.prix}
                          required
                        />
                      </div>
                    </div>
                    {item.propertieProjetId == 2 && (
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Après le prix (ex: "/mois")</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="prefixe_prix"
                            id="prefixe_prix"
                            onChange={this.handleChange}
                            defaultValue={propertieDetail.prefixe_prix}
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Avant le prix (ex: "à partir de ") optionnel
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="sufixe_prix"
                          id="sufixe_prix"
                          onChange={this.handleChange}
                          defaultValue={propertieDetail.sufixe_prix}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Médias</h2>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label>Photos</label>
                      <DropzoneArea
                        acceptedFiles={["image/*", "video/*", "application/*"]}
                        onChange={this.handleChangeFiles}
                        showFileNames
                        dropzoneText="Glissez et déposez vos images ici"
                        showAlerts={false}
                        filesLimit={20}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        {!this.state.dataSending && (
                          <button className="btn btn-round btn-full-blue-violent full-width">
                            Enregistrer les modifications
                          </button>
                        )}

                        {this.state.dataSending && (
                          <button
                            className="btn btn-round btn-full-blue-violent full-width"
                            disabled
                          >
                            <Loader_01 />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateAnnonce;
