import React, { Component } from "react";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Loader_01 from "../../../../loaders/loader_01";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DropzoneArea } from "material-ui-dropzone";

// services call
import Propertie from "../../../../services/propertie";
import Categorie from "../../../../services/categorie";
import Propertie_type from "../../../../services/propertieType";
import villes from "../../../../services/villes";

const Countries = [
  { label: "Albania", value: 355 },
  { label: "Argentina", value: 54 },
  { label: "Austria", value: 43 },
  { label: "Cocos Islands", value: 61 },
  { label: "Kuwait", value: 965 },
  { label: "Sweden", value: 46 },
  { label: "Venezuela", value: 58 },
];

class AnnonceSubscriptionFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserMpspg: JSON.parse(localStorage.getItem("Mpspg")),
      currentUser: [],
      item: [],
      categorieListe: [],
      propertieTypeListe: [],
      zoneListe: [],
      villeCommuneListe: [],
      files: [],
      dataSending: false,
      categoryIdValidator: 0,
      propertieTypeIdValidator: 0,
      zoneIdValidator: 0,
      villeCommuneIdValidator: 0,
      inputValidator: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangCcategory = this.handleChangCcategory.bind(this);
    this.handleChangePropertieType = this.handleChangePropertieType.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeVilleCommune = this.handleChangeVilleCommune.bind(this);
  }

  handleChange = (event) => {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleChangCcategory = (event) => {
    let item = { ...this.state.item };
    item["categoryId"] = event.value;
    this.setState({ item });
  };

  handleChangePropertieType = (event) => {
    let item = { ...this.state.item };
    item["propertieTypeId"] = event.value;
    this.setState({ item });
  };

  handleChangeZone = (event) => {
    let item = { ...this.state.item };
    item["zoneId"] = event.value;
    this.setState({ item });
  };

  handleChangeVilleCommune = (event) => {
    let item = { ...this.state.item };
    item["villeCommuneId"] = event.value;
    this.setState({ item });
  };

  handleChangeFiles(files) {
    this.setState({
      files: files,
    });
  }

  // services function section
  createPropertie = () => {
    const currentUser = JSON.parse(localStorage.getItem("userInfo"));
    const currentUserId = currentUser.id;
    let item = { ...this.state.item };
    item["userId"] = currentUserId;
    this.setState({ item });
    Propertie.createPropertie(item).then((result) => {
      setTimeout(() => {
        localStorage.removeItem("submissionData");
        window.location.href = "/";
      }, 2000);
    });
  };
  getCategorieList = () => {
    Categorie.getCategorieList().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          categorieListe: [...this.state.categorieListe, newFormatData],
        });
      }
    });
  };
  getPropertieTypeList = () => {
    Propertie_type.getPropertie_typeList().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          propertieTypeListe: [...this.state.propertieTypeListe, newFormatData],
        });
      }
    });
  };
  getZoneList = () => {
    villes.getZone().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({ zoneListe: [...this.state.zoneListe, newFormatData] });
      }
    });
  };
  getVilleCommuneList = () => {
    villes.getVillesCommune().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          villeCommuneListe: [...this.state.villeCommuneListe, newFormatData],
        });
      }
    });
  };

  handleSubmission = (event) => {
    event.preventDefault();
    this.setState({ dataSending: true });

    let item = { ...this.state.item };
    item["propertiePrivilegeId"] = 1;
    item["propertieStatusId"] = 1;
    this.setState({ item });

    if (!localStorage.getItem("iLg")) {
      localStorage.setItem("submission", true);
      localStorage.setItem("submissionData", JSON.stringify(item));
    }
  };

  startToSavePropertie = () => {
    if (!localStorage.getItem("submission")) {
      this.setState({ dataSending: false });
    }
    if (localStorage.getItem("iLg")) {
      setTimeout(() => {
        localStorage.removeItem("iLg");
      }, 1000);
      this.createPropertie();
    }
  };

  componentDidMount() {
    localStorage.removeItem("submission");
    this.getCategorieList();
    this.getPropertieTypeList();
    this.getZoneList();
    this.getVilleCommuneList();
    setInterval(() => {
      this.startToSavePropertie();
    }, 2000);
    if (!this.state.inputValidator) {
      setInterval(() => {
        if (
          this.state.item.categoryId &&
          this.state.item.propertieTypeId &&
          this.state.item.villeCommuneId &&
          this.state.item.zoneId
        ) {
          this.setState({ inputValidator: true });
        }
      }, 1000);
    }
  }

  render() {
    const {
      item,
      categorieListe,
      propertieTypeListe,
      zoneListe,
      villeCommuneListe,
      inputValidator,
    } = this.state;

    return (
      <div className="content-page">
        <div className="annonce-form-box">
          <form onSubmit={this.handleSubmission}>
            <div className="row">
              <div className="col-md-12">
                <p>* Ces champs sont obligatoires.</p>
              </div>

              <div className="col-md-12 mb-4">
                <h2>Personne physique</h2>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Prénom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Téléphone</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <h2>Entreprise</h2>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>Nom</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>Téléphone</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>Numéro de registe de commerce</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>Numéro CC</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-4">
                  {!inputValidator && !this.state.dataSending && (
                    <button
                      className="btn btn-round btn-full-blue-violent full-width"
                      disabled
                    >
                      Soumettre ma demande
                    </button>
                  )}
                  {inputValidator && !this.state.dataSending && (
                    <button className="btn btn-round btn-full-blue-violent full-width">
                      Soumettre ma demande
                    </button>
                  )}

                  {this.state.dataSending && (
                    <button
                      className="btn btn-round btn-full-blue-violent full-width"
                      disabled
                    >
                      <Loader_01 />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AnnonceSubscriptionFrom;
