import React, { Component } from "react";
import axios, { post } from "axios";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Loader_01 from "../../../loaders/loader_01";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DropzoneArea } from "material-ui-dropzone";

import titleTransformer from "../../../components/function/titleTransformer";
// services call
import Propertie from "../../../services/propertie";
import Categorie from "../../../services/categorie";
import Propertie_type from "../../../services/propertieType";
import Propertie_image from "../../../services/propertieImage";
import villes from "../../../services/villes";
import S3FileUpload from "react-s3";

//Optional Import
import { uploadFile } from "react-s3";
const config = {
  bucketName: "nymba-files",
  region: "us-west-2",
  accessKeyId: "AKIAQSFK33IDINH5ZBFI",
  secretAccessKey:
    "gIvTD3hgwow08/PKIzcNcd3PePdB4kGXwDmLkAu6+DEkgDxe6veFosBT7eUgEXAMPLE",
};

// S3UploaderModule.forRoot({
//   region: "us-west-2",
//   bucket: "nymba-files",
//   credentials: {
//     accessKeyId: "AKIAQSFK33IDINH5ZBFI",
//     secretAccessKey: "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",
//   },
// }),
class AnonymousAddPropertie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserMpspg: JSON.parse(localStorage.getItem("Mpspg")),
      currentUser: [],
      item: [],
      categorieListe: [],
      propertieTypeListe: [],
      zoneListe: [],
      villeCommuneListe: [],
      files: [],
      filelist: [],
      dataSending: false,
      categoryIdValidator: 0,
      propertieTypeIdValidator: 0,
      zoneIdValidator: 0,
      villeCommuneIdValidator: 0,
      inputValidator: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.handleChangCcategory = this.handleChangCcategory.bind(this);
    this.handleChangePropertieType = this.handleChangePropertieType.bind(this);
    this.handleChangeZone = this.handleChangeZone.bind(this);
    this.handleChangeVilleCommune = this.handleChangeVilleCommune.bind(this);
  }

  handleChange = (event) => {
    console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  onEditorChange = (event, editor) => {
    const name = "body";
    const value = editor.getData();
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  // handleFileSubmission = (file) => {
  //   const formData = new FormData();

  //   formData.append("File", file);

  //   axios(
  //     "https://freeimage.host/api/1/upload?key=6d207e02198a847aa98d0a2a901485a5",
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("Success:", result);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  handleFileSubmission = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // let endpoint = "http://localhost:8088/upload.php";
    // axios.post(endpoint, formData, {}).then((res) => {
    //   console.log("File uploaded!");
    // });
    S3FileUpload.uploadFile(file, config)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));

    //** OR *//

    // uploadFile(file, config)
    //   .then((data) => console.log(data))
    //   .catch((err) => console.error(err));

    /**
     * {
     *   Response: {
     *     bucket: "your-bucket-name",
     *     key: "photos/image.jpg",
     *     location: "https://your-bucket.s3.amazonaws.com/photos/image.jpg"
     *   }
     * }
     */
  };

  handleChangCcategory = (event) => {
    let item = { ...this.state.item };
    item["propertieProjetId"] = event.value;
    this.setState({ item });
  };

  handleChangePropertieType = (event) => {
    let item = { ...this.state.item };
    item["propertieTypeId"] = event.value;
    this.setState({ item });
  };

  handleChangeZone = (event) => {
    let item = { ...this.state.item };
    item["zoneId"] = event.value;
    this.setState({ item });
  };

  handleChangeVilleCommune = (event) => {
    let item = { ...this.state.item };
    item["villeCommuneId"] = event.value;
    this.setState({ item });
  };

  handleChangeFiles(files) {
    this.setState({
      files: files,
    });
    console.log("files", files);
  }

  savePropertieFile = () => {
    console.log("savePropertieFile");

    const files = { ...this.state.files };
    console.log("files", files[0]);
    this.handleFileSubmission(files[0]);
    // for (let i = 0; i < files.length; i++) {
    //   this.handleFileSubmission(files[i]);
    // }
  };

  // services function section
  createPropertie = () => {
    const currentUser = JSON.parse(localStorage.getItem("userInfo"));
    const currentUserId = currentUser.id;
    this.savePropertieFile();
    let item = { ...this.state.item };
    item["userId"] = currentUserId;
    this.setState({ item });
    Propertie.createPropertie(item).then((result) => {
      const formData = new FormData();
      // formData.append("files", this.state.files);
      // Read selected files
      for (let i = 0; i < this.state.files.length; i++) {
        const isformat = formData.append("files", this.state.files[i]);
        this.setState({
          filelist: [...this.state.filelist, isformat],
        });
      }
      formData.append("propertyId", result.data.data.id);

      Propertie_image.createPropertie_image(formData).then((result) => {
        setTimeout(() => {
          localStorage.removeItem("submissionData");
          window.location.href = "/agent/annonces";
        }, 2000);
      });
    });
  };
  getCategorieList = () => {
    Categorie.getCategorieList().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          categorieListe: [...this.state.categorieListe, newFormatData],
        });
      }
    });
  };
  getPropertieTypeList = () => {
    Propertie_type.getPropertie_typeList().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          propertieTypeListe: [...this.state.propertieTypeListe, newFormatData],
        });
      }
    });
  };
  getZoneList = () => {
    villes.getZone().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          zoneListe: [...this.state.zoneListe, newFormatData],
        });
      }
    });
  };
  getVilleCommuneList = () => {
    villes.getVillesCommune().then((result) => {
      const data = result.data;
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id };
        this.setState({
          villeCommuneListe: [...this.state.villeCommuneListe, newFormatData],
        });
      }
    });
  };

  handleSubmission = (event) => {
    event.preventDefault();
    this.setState({ dataSending: true });

    let item = { ...this.state.item };
    item["propertiePrivilegeId"] = 1;
    item["propertieStatusId"] = 1;

    item["link_prefix"] = titleTransformer(item.title);
    this.setState({ item });

    if (!localStorage.getItem("iLg")) {
      localStorage.setItem("submission", true);
      localStorage.setItem("submissionData", JSON.stringify(item));
    }
  };

  startToSavePropertie = () => {
    if (!localStorage.getItem("submission")) {
      this.setState({ dataSending: false });
    }
    if (localStorage.getItem("iLg")) {
      setTimeout(() => {
        localStorage.removeItem("iLg");
      }, 1000);
      this.createPropertie();
    }
  };

  componentDidMount = () => {
    localStorage.removeItem("submission");
    this.getCategorieList();
    this.getPropertieTypeList();
    this.getZoneList();
    this.getVilleCommuneList();
    setInterval(() => {
      this.startToSavePropertie();
    }, 2000);
    if (!this.state.inputValidator) {
      setInterval(() => {
        if (
          this.state.item.propertieProjetId &&
          this.state.item.propertieTypeId &&
          this.state.item.villeCommuneId &&
          this.state.item.zoneId
        ) {
          this.setState({ inputValidator: true });
        }
      }, 1000);
    }
  };

  render() {
    const {
      item,
      categorieListe,
      propertieTypeListe,
      zoneListe,
      villeCommuneListe,
      inputValidator,
    } = this.state;

    return (
      <div className="content-page">
        <div className="add-propertie-form-box">
          <form onSubmit={this.handleSubmission}>
            <div className="row">
              <div className="col-md-12 mb-4">
                <p>
                  * Ces champs sont obligatoires : Titre,Type d'annonce, Type de
                  bien, Zone, Ville / commmune, Prix, Médias de propriété
                </p>
              </div>

              <div className="col-md-12 mb-4">
                <h2>Description du bien</h2>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label>* Titre (obligatoire)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>* Type d'annonce</label>
                <Select
                  options={categorieListe}
                  name="propertieProjetId"
                  id="propertieProjetId"
                  onChange={this.handleChangCcategory}
                  placeholder=""
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>* Type de bien</label>
                <Select
                  options={propertieTypeListe}
                  name="propertieTypeId"
                  id="propertieTypeId"
                  onChange={this.handleChangePropertieType}
                  placeholder=""
                />
              </div>
              <div className="col-md-12 mb-4">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.item.body}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={this.onEditorChange}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>

              <div className="col-md-12 mb-4">
                <h2>Localisation</h2>
              </div>
              <div className="col-md-6 mb-4">
                <label>* Zone</label>
                <Select
                  options={zoneListe}
                  name="zoneId"
                  id="zoneId"
                  onChange={this.handleChangeZone}
                  placeholder=""
                />
              </div>
              <div className="col-md-6 mb-4">
                <label>* Ville / commmune</label>
                <Select
                  options={villeCommuneListe}
                  name="villeCommuneId"
                  id="villeCommuneId"
                  onChange={this.handleChangeVilleCommune}
                  placeholder=""
                />
              </div>

              <div className="col-md-12 mb-4">
                <h2>Tarif</h2>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label>* Prix en Fcfa (chiffre seulement)</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    name="prix"
                    id="prix"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              {item.propertieProjetId == 2 && (
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label>Après le prix (ex: "/mois")</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="prefixe_prix"
                      id="prefixe_prix"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <label>Avant le prix (ex: "à partir de ") optionnel</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="sufixe_prix"
                    id="sufixe_prix"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <h2>Médias</h2>
              </div>
              <div className="col-md-12 mb-4">
                <label>Photos</label>
                <DropzoneArea
                  acceptedFiles={["image/*", "video/*", "application/*"]}
                  onChange={this.handleChangeFiles.bind(this)}
                  showFileNames
                  dropzoneText="Glissez et déposez vos images ici"
                  showAlerts={false}
                  filesLimit={20}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group mb-4">
                  {!inputValidator && !this.state.dataSending && (
                    <button
                      className="btn btn-round btn-full-blue-violent full-width"
                      disabled
                    >
                      Soumettre la propriété
                    </button>
                  )}
                  {inputValidator && !this.state.dataSending && (
                    <button className="btn btn-round btn-full-blue-violent full-width">
                      Soumettre la propriété
                    </button>
                  )}

                  {this.state.dataSending && (
                    <button
                      className="btn btn-round btn-full-blue-violent full-width"
                      disabled
                    >
                      <Loader_01 />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default AnonymousAddPropertie;
