import React, { Component } from "react";
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";
import Loader_01 from "../../../../loaders/loader_01";

import Categorie from "../../../../services/categorie";
import account from "../../../../services/account";

class AccountListPage extends Component {
  constructor(props) {
    super(props);
    this.state = { accountListe: [], item: [], dataSending: false };
  }

  createUser = () => {
    this.setState({ dataSending: true });
    let item = { ...this.state.item };
    this.setState({ item });
    Categorie.createCategorie(item).then(() => {
      this.setState({ dataSending: false });
      this.componentDidMount();
    });
  };
  getAccountList = () => {
    account.getUserList().then((result) => {
      const data = result.data;
      this.setState({
        accountListe: data,
      });
    });
  };

  componentDidMount() {
    this.getAccountList();
  }

  render() {
    const { accountListe } = this.state;
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Liste des utilisateurs</h2>
        </div>
        <div className="dsh-content">
          <div className="row">
            <div className="col-md-12 mb-4">
              <p>* Ces champs sont obligatoires</p>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-md-4">
              <div className="card__o userinfo p-3 b-r-10">
                <form onSubmit={this.handleSubmission}>
                  <div className="profile--item">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>* Titre (obligatoire)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="title"
                            id="title"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>Description</label>
                          <textarea
                            cols="120"
                            rows="5"
                            className="form-control"
                            placeholder=""
                            name="description"
                            id="description"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              Enregistrer
                            </button>
                          )}

                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader_01 />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="card__o userinfo p-3 b-r-10">
                <div className="list--item">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Nom</th>
                            <th>email</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountListe.map((item, index) => (
                            <tr key={index}>
                              <td>{item.first_name + " " + item.last_name} </td>
                              <td> {item.email} </td>
                              <td>
                                <button
                                  className="btn"
                                  // onClick={() => this.handleEdit(item)}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button
                                  className="btn"
                                  // onClick={() =>
                                  //   this.deleteVille_commune(item?.id)
                                  // }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountListPage;
