import React, { Component } from "react";
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";
import Loader_01 from "../../../../loaders/loader_01";

import Categorie from "../../../../services/categorie";

class FieldCategorie extends Component {
  constructor(props) {
    super(props);
    this.state = { categorieListe: [], item: [], dataSending: false };
  }

  createPropertie = () => {
    this.setState({ dataSending: true });
    let item = { ...this.state.item };
    this.setState({ item });
    Categorie.createCategorie(item).then(() => {
      this.setState({ dataSending: false });
      this.componentDidMount();
    });
  };
  getPropertieTypeList = () => {
    Categorie.getCategorieList().then((result) => {
      const data = result.data;
      this.setState({
        categorieListe: data,
      });
    });
  };

  componentDidMount() {
    this.getPropertieTypeList();
  }

  render() {
    const { categorieListe } = this.state;
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Categories</h2>
        </div>
        <div className="dsh-content">
          {/* <div
            className="alert-with-icon alert alert-warning fade show"
            role="alert"
          >
            <div className="container">
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-bell-55"></i> Après soumission de votre
                  propriété, celle ci est soumise à une validation dans les 48h
                  avant d'etre publiée
                </div>
              </div>
            </div>
          </div>
          */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <p>* Ces champs sont obligatoires</p>
            </div>
          </div>

          <div className="row">
            {" "}
            <div className="col-md-4">
              <div className="card__o userinfo p-3 b-r-10">
                <form onSubmit={this.handleSubmission}>
                  <div className="profile--item">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>* Titre (obligatoire)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="title"
                            id="title"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>Description</label>
                          <textarea
                            cols="120"
                            rows="5"
                            className="form-control"
                            placeholder=""
                            name="description"
                            id="description"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              Enregistrer
                            </button>
                          )}

                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader_01 />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card__o userinfo p-3 b-r-10">
                <div className="list--item">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>Nom</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categorieListe.map((item, index) => (
                            <tr key={index}>
                              <td> {item.id} </td>
                              <td> {item.title} </td>
                              <td> {item.description} </td>
                              <td>
                                <Nav className="order-actions-group flex-center">
                                  <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle
                                      aria-expanded={false}
                                      aria-haspopup={true}
                                      color="default"
                                      data-toggle="dropdown"
                                      href="#account"
                                      id="dropdownMenuButton"
                                      className="order-settings-button p-0"
                                      nav
                                      onClick={(e) => e.preventDefault()}
                                      role="button"
                                    >
                                      <i className="nc-icon nc-settings-gear-65"></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      aria-labelledby="dropdownMenuButton"
                                      className="dropdown-info"
                                    >
                                      <button className="dropdown-item">
                                        Modifier
                                      </button>
                                    </DropdownMenu>
                                    <DropdownMenu
                                      aria-labelledby="dropdownMenuButton"
                                      className="dropdown-info"
                                    >
                                      <button className="dropdown-item">
                                        Supprimer
                                      </button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </Nav>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FieldCategorie;
