import React, { Component } from "react";

import AnnonceItem from "../../Dashboard/Component/AnnonceItem";

// services import

import Propertie from "../../../../../services/propertie";

const currentUser = JSON.parse(localStorage.getItem("userInfo"));
const currentUserId = currentUser?.id;
const currentUsername = currentUser?.username;
class PublishPropertie extends Component {
  constructor(props) {
    super(props);
    this.state = { propertieListe: [] };
  }

  getUserActivedProperties = () => {
    Propertie.getPublishedUserPropertieList(currentUserId).then((result) => {
      this.setState({ propertieListe: result.data });
    });
  };

  EditPropertieStatus = (propertieId, statusId) => {
    let data = {
      propertieStatusId: 2,
    };

    Propertie.updatePropertie(propertieId, data).then(() =>
      this.componentDidMount()
    );
  };

  componentDidMount() {
    this.getUserActivedProperties();
  }

  render() {
    const { propertieListe } = this.state;
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Mes annonces</h2>
        </div>
        <div className="dsh-content">
          <div className="card user-active-properties-box">
            <h2 className="mb-4">
              <strong>Propriétés publiée ({propertieListe.length}) </strong>{" "}
            </h2>
            <div className="dashboard_table_list_header mb-3 row">
              <div className="col-md-4">Propriété</div>
              <div className="col-md-2">Catégorie</div>
              <div className="col-md-2">Statut</div>
              <div className="col-md-2">Prix </div>
              <div className="col-md-2">Actions</div>
            </div>
            {propertieListe.map((item, index) => (
              <AnnonceItem
                key={index}
                item={item}
                EditPropertieStatus={this.EditPropertieStatus}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PublishPropertie;
