import Dashboard from "./views/pages/Admin_views/Dashboard/Dashboard";
import PublishPropertie from "./views/pages/Admin_views/Annonces/PublishPropertie/PublishPropertie";
import Profil from "./views/pages/Admin_views/Profil/Profil";
import UserList from "./views/pages/Admin_views/Profil/UserList";
import AddAnnonce from "./views/pages/Admin_views/Annonces/AddAnnonce/AddAnnonce";
import UpdateAnnonce from "./views/pages/Admin_views/Annonces/UpdateAnnonce/UpdateAnnonce";
import PendingAnnonce from "./views/pages/Admin_views/Annonces/PendingAnnonce/PendingAnnonce";
import FieldPropertyType from "./views/pages/Admin_views/Settings/FieldPropertyType";
import FieldCategorie from "./views/pages/Admin_views/Settings/FieldCategorie";
import FieldZone from "./views/pages/Admin_views/Settings/FieldZone";
import FieldCommune from "./views/pages/Admin_views/Settings/FieldCommune";

const Routes = [
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/annonces",
    exact: true,
    name: "PublishPropertie",
    component: PublishPropertie,
  },
  {
    path: "/admin/users",
    exact: true,
    name: "UserList",
    component: UserList,
  },
  {
    path: "/admin/mon-profil",
    exact: true,
    name: "Profil",
    component: Profil,
  },
  {
    path: "/admin/annonce/add",
    exact: true,
    name: "AddAnnonce",
    component: AddAnnonce,
  },
  {
    path: "/admin/annonces/:propertieId/update",
    exact: true,
    name: "UpdateAnnonce",
    component: UpdateAnnonce,
  },
  {
    path: "/admin/annonces-pending",
    exact: true,
    name: "PendingAnnonce",
    component: PendingAnnonce,
  },
  {
    path: "/admin/property-type",
    exact: true,
    name: "FieldPropertyType",
    component: FieldPropertyType,
  },
  {
    path: "/admin/categories",
    exact: true,
    name: "FieldCategorie",
    component: FieldCategorie,
  },
  {
    path: "/admin/zone",
    exact: true,
    name: "FieldZone",
    component: FieldZone,
  },
  {
    path: "/admin/commune",
    exact: true,
    name: "FieldCommune",
    component: FieldCommune,
  },
];

export default Routes;
