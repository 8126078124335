import React, { Component } from "react";
import propertie from "../../../../services/propertie";
import notFoundImg from "../../../../assets/img/browser.png";
import LoaderData from "../../../../loaders/loader_01";
import PropertieItem from "../../../../components/properties/propertieItem";

class AnnonceKylimoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertiePremiumListe: [],
    };
  }

  getPremiumProperties = () => {
    this.setState({ fetchindData: true });
    propertie.getPropertiePremiumList().then((result) => {
      this.setState({ propertiePremiumListe: result.data });
      setTimeout(() => {
        this.setState({ fetchindData: false });
      }, 2000);
    });
  };

  componentDidMount() {
    this.getPremiumProperties();
  }

  render() {
    const { propertiePremiumListe, fetchindData } = this.state;
    return (
      <>
        <div className="search-form--box">
          <div className="container">
            {/* <Searchform /> */}
            <h2 className="section-title">Annonces Kylimo</h2>
          </div>
        </div>
        <div className="container">
          {fetchindData ? (
            <div className="text-center content-loading">
              <LoaderData />
            </div>
          ) : (
            <div className="kylomo-section section-standard">
              {propertiePremiumListe.length > 0 ? (
                <div className="row">
                  {propertiePremiumListe.map((item, index) => (
                    <div className="col-md-4">
                      <PropertieItem item={item} key={index} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="not-found-box text-center">
                  <img src={notFoundImg} alt="" />
                  <p className="mt-3">
                    Aucun resultat trouvé pour votre recherche
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

AnnonceKylimoList.propTypes = {};

export default AnnonceKylimoList;
